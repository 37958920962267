.info-value {
    font-family: monospace;
}

.info-card .card-body {
    padding: 10px;
}

.info-card .card-body:hover {
    cursor: default;
}


.card.info-card {
    color: rgba(255, 174, 52, 0.9);
    background: rgba(255, 174, 52, 0.1);
    outline: 1px solid rgba(255, 174, 52, 0.5);
    border-radius: 2px;
    backdrop-filter: blur(5px);
} 


.etherscan-link {
    color: rgba(255, 174, 52, 0.9) !important;
    margin-left: 0rem;
}

.row-space {
    margin-bottom: 1rem;
}

.message-box {
    position: absolute !important;
    z-index: 999;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;

}

.first-p {
    margin-top: 1rem;
}



.accordion-item {
    background-color: rgba(0, 0, 0, 0) !important;

}

.accordion-button {
    background-color: rgba(0, 0, 0, 0) !important;
    border-bottom-color: #ffae34e6 !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    color: rgba(255, 174, 52, 0.9) !important;
    outline: 0px none rgba(255, 174, 52, 0.5) !important;
    

}

.accordion-list {
    padding-left: 0px !important;
}



.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffae34e6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important
}

.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffae34e6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important
}

.accordion-button:focus {
    border-color: #e99f30 !important;
    box-shadow: 0 0 0 0.25rem #e99f30 !important;
}

.external-link {
    color: #e99f30 !important;
    text-decoration: none;
}

.external-link:hover {

    text-decoration: underline !important; 
}