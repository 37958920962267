.node {
    box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.5);

    text-align: left;
    line-height: normal;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;

}

.contract-node {
    background-color: rgba(74, 105, 1, 0.9);
}

.contract-other {
    background-color: rgba(74, 105, 1, 0.5);
}

.account-node {
    background-color: rgb(117, 0, 0);
}

.call-type {
    text-transform: uppercase;
    font-size: 2.5rem;
}


.file-view {
  
    padding: 1rem;

}



.three-container {
    cursor: grab;
}

.node:hover {
    cursor: pointer;
}

.external-node {
    

    background-color: rgba(0, 127, 127, 0.4);

}

.local-node {
   

    background-color: rgba(0, 127, 127, 0.9);
}

.error-node {
    background-color: rgba(206, 43, 43, 0.9);
}

.edge {
    background: #eee;
    display: block;
}


.node .file-title {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-size: 65px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.75);
    /* text-shadow: 0 0 10px rgba(0,255,255,0.95); */

}

 .seq-input-data {
 
    font-family: monospace;
   

}

.node .file-info {
    margin-top: 1rem;
    font-size: 30px;
    word-break: break-all;
    color: rgba(255, 255, 255, 0.75);
    /* text-shadow: 0 0 10px rgba(0,255,255,0.95); */

}

.node .tx-info {
    margin-top: 1rem;
    font-size: 50px;
    word-break: break-all;
    color: rgba(255, 255, 255, 0.75);
    /* text-shadow: 0 0 10px rgba(0,255,255,0.95); */

}

.kind-info {
    margin-top: 1rem;
    text-transform: uppercase;


    word-break: break-all;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.75);
    /* text-shadow: 0 0 10px rgba(0,255,255,0.95); */

}

.node .kind-info {

    font-size: 50px;

}


.view-container {
    max-width: 720px;
}

.view-container .kind-info {

    font-size: 1rem;


}

.view-container .title {


    font-size: 2rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.75);
    /* text-shadow: 0 0 10px rgba(0,255,255,0.95); */

}

.view-headline {
    margin-top: 2rem;
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.2rem;
    font-weight: bold;
}

.error {
    color: rgb(255, 72, 72) !important;
}
