.menu-btn-space {
    margin-left: 2rem !important;
}


.menu-btn {
    color: rgba(255, 174, 52, 0.9) !important;
    background: rgba(255, 174, 52, 0.1) !important;
    outline: 1px solid rgba(255, 174, 52, 0.5) !important;
    border: 0px;
    padding: 5px 10px;
    cursor: pointer !important;
    border-radius: 0px !important;
    margin-left: 10px;
}

.menu-btn:hover{
    color: rgba(255, 174, 52, 1) !important;
    background: rgba(255, 174, 52, 0.3) !important;
  
}

.menu-btn:focus,
.menu-btn.focus {
    box-shadow: 0 0 0 .2rem rgba(255, 174, 52, 1) !important
}

.menu {
    position: absolute;
    z-index: 999;
    bottom: 10px;
    right: 10px;
    max-width: 500px;
    min-width: 200px;
    text-align: left;
}

.menu-end {
    padding-right: 10px;
    min-width: 350px;
}

.help-info {
    width: 400px;
}

