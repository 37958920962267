

.view-select {
    color: rgba(255, 174, 52, 0.9);
    background: rgba(255, 174, 52, 0.1);
    outline: 1px solid rgba(255, 174, 52, 0.5);
    border: 0px;
    padding: 4px;
    cursor: pointer;
    border-radius: 0px !important;
    margin-left: 10px;
}

.view-select:hover{
    color: rgba(255, 174, 52, 1);
    background: rgba(255, 174, 52, 0.3);
  
}

.view-select:focus,
.view-select.focus {
    box-shadow: 0 0 0 .2rem rgba(255, 174, 52, 1)
}